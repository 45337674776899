.usecase{
    height:100%;
    width: 100%;
    color: var(--color-text);
    padding: 5rem 10rem;
     /* ff 3.6+ */
    background-color: #171616;
    background-image: url('../../assets/nft_usecase.jpg');
    background-position: center center;
    /*background-attachment: fixed;*/
}
.usecase_header h2{
    color: #fff;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.usecase_header p{
    color: #fff;
    max-width: 750px;
    font-weight: 500;
    min-width: 250px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.usecase_header div{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
.cards{
    color: #171616;
    width: 100%;
    height: 100%;
    padding: 2rem;
    justify-content: space-around;
}
.cards .card{
    display: flex;
    background-color: #fff;
    margin: 1rem;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    border-radius: 15px;
    border: none;
    min-height: 450px;
    max-height: 450px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%)
}
.cards .card img{
    width: 100% !important;
    border-radius: 10px 10px 0px 0px;
    max-height: 200px;
    min-height: 150px;
    border:0;
    outline:none;
}
.card_content{
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
}
.card_content p{
    padding: 1rem;
    
}
@media screen and (max-width: 1050px) {

    .cards{
        padding: 0rem;
    }
    .usecase{
        padding: 1.5rem 5rem;
    }
    .cards .card h2{
        font-size: 20px;
    }
    .cards .card p{
        font-size: 14px;
    }
}
@media screen and (max-width: 700px) {
    .usecase{
        padding: 1.5rem 2rem;
    }
    
}