.services{
    height:100%;
    width: 100%;
    color: var(--color-text);
    padding: 5rem 10rem;
     /* ff 3.6+ */
     background:-moz-radial-gradient(circle at 100% 100%, rgba(0, 0, 0, 0.42) 0%, rgba(255, 255, 255, 1) 100%);

     /* safari 5.1+,chrome 10+ */
     background:-webkit-radial-gradient(circle at 100% 100%, rgba(0, 0, 0, 0.42) 0%, rgba(255, 255, 255, 1) 100%);
 
     /* opera 11.10+ */
     background:-o-radial-gradient(circle at 100% 100%, rgba(0, 0, 0, 0.42) 0%, rgba(255, 255, 255, 1) 100%);
 
     /* ie 10+ */
     background:-ms-radial-gradient(circle at 100% 100%, rgba(0, 0, 0, 0.42) 0%, rgba(255, 255, 255, 1) 100%);
 
     /* global 92%+ browsers support */
     background:radial-gradient(circle at 100% 100%, rgba(0, 0, 0, 0.42) 0%, rgba(255, 255, 255, 1) 100%);
}
.services_header h2{
    color: #171616;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.services_header p{
    color: #171616;
    max-width: 750px;
    font-weight: 500;
    min-width: 250px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.services_header div{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
.services_container{
    display: flex;
    margin-top: 3.5rem;
}
.services_container_image img{
    width: 100%;
    border-radius: 20px;
    -webkit-transition: all 0.50s;
    transition: all 0.50s;
}
.services_container_image img:hover{
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    transform: scale(1.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.service_board{
    margin-top: 4rem;
    background: var(--gradient-text);
    color: white;
    border-radius: 20px;
    
    padding: 2rem;
}
.service_board h3{
    font-weight: 800;
    padding: 0.5rem;
}
.service_board p{
    max-width: 700px;
    padding: 0.5rem;
}
.service_board button{
    padding: 0.8rem;
    margin: 0.5rem;
    color: #171616;
    background-color: #fff;
    border-radius: 5px;
    
    border: 2px solid white;
    font-size: 17px;
    font-weight: 550;
}
.service_board button:hover{
    color: #fff;
    background: none;
    cursor: pointer;
}
.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 15px;
    color: #171616;
}

.faqs .faq {
	margin: 15px;
	padding: 15px;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.faqs .faq:hover{
	cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.faqs .faq .faq-question {
	position: relative;
	font-size: 20px;
	padding-right: 80px;
    font-weight: 500;
	transition: all 0.1s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%) rotate(-90deg);
	width: 20px;
	height: 20px;
	background-image: url('../../assets/arrow-down-mint.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.1s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(0deg);
}



.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}
.faqs .faq.open{
    background:linear-gradient(90deg, rgba(37, 134, 224, 1) 0%, rgba(27, 180, 255, 1) 50%, rgba(31, 218, 244, 1) 100%);
    color: #fff;
}



@media screen and (max-width: 1050px) {
    .services_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    .services_container{
        flex-direction: column;
    }
    .services{
        padding: 1.5rem 6rem;
    }
    .faqs {
        padding: 0;
    }
    .faqs .faq {
        margin: 15px 0;
    }

    
    
}
@media screen and (max-width: 700px) {
    .services{
        padding: 1.5rem 2rem;
    }
    
}