
.header{
    
    background-color: #fff;
    padding: 0rem 0rem 6rem 0rem;
    height: 100%;
}
.header_background{
    position: absolute;
    display: flex;
}
.header_container{
    position: relative;
    display: flex;
    padding: 6rem;
}
.header-content{
    flex: 1;
    display: flex;
    
}
.header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.header-content h1{
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    padding: 1rem 0;
    letter-spacing: -0.04em;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
}
.header-content p{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;

    color: var(--color-text);
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
    margin-top: 1.5rem;
}
.header-content button{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    background: var(--gradient-text);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    margin-top: 1.5rem;
}
.header-content button:hover{
    background: #1FDAF4;
    cursor: pointer;
}
.header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-image img{
    width: 100%;
    height: 100%;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

@media screen and (max-width: 1050px) {
    .header_container{
        flex-direction: column;
    }
    .header-content{
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 650px) {
    .header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .header p{
        font-size: 18px;
        line-height: 24px;
    }
    .header-content__people{
        flex-direction: column;
    }
    .header-content__people p{
        margin: 0;
    }
}

@media screen and (max-width: 490px) {
    .header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .header p{
        font-size: 18px;
        line-height: 24px;
    }
}