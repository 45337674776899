.policy{
    color:#171616;
    padding: 5rem 10rem;
}
.policy p{
    padding: 1rem;
}
@media screen and (max-width: 1050px) {
    .policy{
        padding: 1.5rem 6rem;
    } 
 
}
@media screen and (max-width: 700px) {
    .policy{
        padding: 1.5rem 2rem;
    }
    
}