
.getintouch_header{
    background-color: #fff;
    padding: 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}
.getintouch_header img{
    max-width: 700px;
    width: 100%;
    padding: 1rem;
}
.getintouch_text{
    padding: 1rem;
}
.getintouch_text h2{
    color: #171616;;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.getintouch_text h3{
    color: #171616;;
    font-weight: 800;
    font-size: 32px;
    max-width: 500px;
    padding: 2rem;
}
.getintouch_text button{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    background: var(--gradient-text);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    margin-top: 1.5rem;
}
.getintouch_text button:hover{
    background: #1FDAF4;
    cursor: pointer;
}
.getintouch_text p{
    color: #171616;;
    max-width: 750px;
    font-weight: 500;
    min-width: 350px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.getintouch_text div{
    width: 120px;
    height: 5px; 
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
@media screen and (max-width: 1050px) {
    .getintouch_header h2{
        color: #606060;
        font-weight: 800;
        font-size: 34px;
    }
    .getintouch_header{
        flex-direction: column;
    }
    .getintouch_header{
        padding: 1.5rem 6rem;
    }

    
    
}
@media screen and (max-width: 700px) {
    .getintouch_header{
        padding: 1.5rem 2rem;
    }
    
}