.counter{
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-left: 2rem;
    padding: 1.5rem;
}
.counter_header{
    font-weight: 800;
    font-size: 55px;
    line-height: 75px;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.counter p{
    background: var(--color-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
}
@media screen and (max-width: 700px) {
    .counter_header{
        font-size: 42px;
        line-height: 50px;
    }
    .counter p{
        font-size: 12px;
        line-height: 22px;
    }

}