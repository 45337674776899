.aboutus{
    height:100%;
    width: 100%;
    color: var(--color-text);
    padding: 5rem 10rem;
     /* ff 3.6+ */
    color: #171616;
    /*background-attachment: fixed;*/
}
.aboutus_header h2{
    color: #171616;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.aboutus_header h3{
    color: #171616;
    max-width: 1600px;
    text-align: center;
    font-weight: 800;
    min-width: 250px;
    font-size: 30px;
    padding: 3.5rem;
}
.aboutus_header .line{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
.aboutus_image img{
    width: 100%;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
}
.team{
    width: 100%;
    background: var(--gradient-text);
    padding: 5rem 10rem;
}
.team h2{
    color: #fff;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.team p{
    font-weight: 500;
    min-width: 250px;
    font-size: 25px;
}
.team_container{
    padding: 3.5rem;
}
.team_founders{
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 3rem 0rem 0rem 0rem;
}
.team_founders img{
    width: 250px;
    height: 250px;
    border-radius: 250px;
}
.team_founder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.team_founder h3{
    margin-top: 0.5rem;
}

@media screen and (max-width: 1050px) {
    .aboutus_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    .team_founders{
        flex-direction: column;
    }
    .aboutus{
        padding: 1.5rem 6rem;
    }

    .team{
        padding: 1.5rem 6rem;
    }
    .team_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    
    
}
@media screen and (max-width: 700px) {
    .aboutus{
        padding: 1rem 2rem;
    }
    .team{
        padding: 1rem 2rem;
    }
    
}