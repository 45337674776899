.faqcontent{
    color:#171616;
    padding: 5rem 10rem;
}
.faqcontent_header h2{
    color: #171616;
    font-weight: 800;
    font-size: 45px;
}
.faqcontent_header div{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
.faqcontent_header .faq {
	margin: 15px;
	padding: 15px;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.faqs2 {
	width: 100%;
	margin: 0 auto;
    padding: 2rem;
}

.faqs2 .faq {
	margin: 15px;
	padding: 2rem;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.faqs2 .faq:hover{
	cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.faqs2 .faq .faq-question {
	position: relative;
	font-size: 25px;
	padding-right: 80px;
    font-weight: 800;
	transition: all 0.1s ease;
}

.faqs2 .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%) rotate(-90deg);
	width: 30px;
	height: 30px;
	background-image: url('../../assets/arrow-down-mint.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.1s ease-out;
}

.faqs2 .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
    max-width: 65%;
}

.faqs2 .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs2 .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(0deg);
}



.faqs2 .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}
.faqs2 .faq.open{
    background:#1c1a1a;
    color: #fff;
}
@media screen and (max-width: 1050px) {
    .faqcontent_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    .faqcontent_container{
        flex-direction: column;
    }
    .faqcontent{
        padding: 1.5rem 6rem;
    }
    .faqs2 {
        padding: 1rem 0;
    }
    .faqs2 .faq {
        margin: 15px 0;
    }

    
    
}
@media screen and (max-width: 700px) {
    .faqcontent{
        padding: 1.5rem 2rem;
    }
	.faqs2 .faq .faq-question {
        font-size: 20px;
    }
    .faqs2 .faq {
		padding: 1rem;
	}
    
}
