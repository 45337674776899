.navbar{
    background-color: #fff;
    color: var(--color-text);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem 6rem 2rem 6rem;
}

.navbar_logo{
    margin-right: 2rem;
}

.navbar_logo img {
    height: 20px; 
}

.navbar_container{
    display: flex;
    flex-direction: row;
}

.navbar_container p,
.navbar-menu_container p{
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    min-width: 75px;
    margin: 0 1rem;
    cursor: pointer;
    padding: 0rem 2rem;
    
}
.navbar-sign button,
.navbar-menu_container button{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    background: var(--gradient-text);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;

    border:0;
    outline:none;
}
.navbar-sign button:hover,
.navbar-menu_container button:hover{
    background: #1FDAF4;
    cursor: pointer;
}
.navbar-menu_container p:hover,
.navbar_container p:hover{
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.navbar-menu{
    margin: 1rem;
    display: none;
    position:relative;
}
.navbar-menu svg{
    cursor: pointer;
}
.navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 2;
    text-align: end;
    background-color: #fff;
    padding:2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);  
}

.navbar-menu_container p{
    margin: 1rem 0;
}

@media screen and (max-width: 1050px) {
    .navbar_container{
        display: none;
    }
    .navbar-menu{
        display: flex;
    }
    .navbar-menu_container-links-sign{
        display: none;
    }
    .navbar-sign{
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .navbar{
        padding: 2rem 4rem;
    }
}
@media screen and (max-width: 550px) {
    .navbar{
        padding: 2rem;
    }
    .navbar-menu_container{
        top: 20px;
    }
    .navbar-menu_container-links-sign{
        display: block;
    }
}