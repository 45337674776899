.footer{
    background-color: #1a1a1a;
    padding: 4rem 5rem 5rem 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}
.footer_contact{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
}
.footer_contact h3{
    padding: 0.5rem 0rem;
}
.footer_contact p{
    padding: 0.1rem;
}
.footer_menue{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.footer_menue_tab{
    padding: 1rem;
    font-size: 18px;
    font-weight: 600;
}
.footer_menue_tab:hover{
    cursor: pointer;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}
.footer button{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    background: var(--gradient-text);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    margin-left: 3rem;
}
.footer button:hover{
    background: #1FDAF4;
    cursor: pointer;

}
@media screen and (max-width: 1050px) {

    .footer{
        padding: 1.5rem 6rem;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
    .footer_menue{
        flex-direction: column;
    }
    .footer_menue button{
        margin: 0;
    }
    .footer_menue_tab{
        padding: 0.2rem;
    }
}
@media screen and (max-width: 700px) {
    .footer{
        padding: 0.5rem 0.5rem;
    }
    
}