.blockchain{
    background-color: #A0A0A0;
    padding: 5rem 10rem;
}
.blockchain_header h2{
    color: #171616;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.blockchain_header p{
    color: #171616;
    max-width: 750px;
    font-weight: 500;
    min-width: 250px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.blockchain_header div{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}

.chains {
	width: 100%;
	margin: 0 auto;
    padding: 2rem;
}

.chains .chain {
	margin: 15px;
	padding: 2rem;
	background-color: #FFF;
    color: #171616;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.chains .chain:hover{
	cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.chains .chain .chain-question {
	position: relative;
    font-size: 25px;
    padding-right: 80px;
    font-weight: 800;
    display: flex;
    align-items: center;
}

.chains .chain .chain-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%) rotate(-90deg);
	width: 30px;
	height: 30px;
	background-image: url('../../assets/arrow-down-mint.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.1s ease-out;
}

.chain-question img{
    height: 50px;
    margin: 0.5rem;
}
.chains .chain .chain-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
    max-width: 90%;
}

.chains .chain.open .chain-question {
	margin-bottom: 15px;
}

.chains .chain.open .chain-question::after {
	transform: translateY(-50%) rotate(0deg);
}



.chains .chain.open .chain-answer {
	max-height: 1000px;
	opacity: 1;
}
.chains .chain.open{
    background:#1c1a1a;
    color: #fff;
}
@media screen and (max-width: 1050px) {
    .blockchain_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    .chains {
		padding: 1rem;
	}
    .chain {
        padding: 1rem 0;
    }
    .chains .chain {
        margin: 15px 0;
    }
    .blockchain{
        padding: 2.5rem 5rem;
    }

    
     
}
@media screen and (max-width: 700px) {
    .blockchain{
        padding: 1.5rem 2rem;
    }
	.chains .chain .chain-question {
        font-size: 20px;
    }
    .chains {
		padding: 0rem;
	}
    .chain-question img{
        height: 35px;
        margin: 0.5rem;
    }
    
}