.submitted{
    display:none;
    color:#171616;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.contact_privacy a{
    color: #2586E0;
}
.submitted h2{
    align-items: center;
    font-size: 38px;
    text-align: center;
}
.submitted p{
    background-color: #a5d6a7;
    font-weight: 800;
    color: white;
    padding: 2rem 6rem;
    font-size: 18px;
    border-radius: 20px;
    margin: 1rem;
    text-align: center;
}
.contact{
    background-color: #fff;
    color: #171616;
    padding: 5rem 10rem;
}
.contact_header h2{
    color: #171616;;
    font-weight: 800;
    font-size: 45px;
    max-width: 1000px;
}
.contact_header p{
    color: #171616;;
    max-width: 750px;
    font-weight: 500;
    min-width: 350px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.contact_header div{
    width: 120px;
    height: 5px; 
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
.form{
    padding: 2rem;
}
.form label{
    font-size: 16px;
    padding: 0.2rem 0rem;
}
.form button{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    background: var(--gradient-text);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    margin-top: 1.5rem;
}
.form button:hover{
    background: #1FDAF4;
    cursor: pointer;
}
.message{
    height:250px;
    border-radius: 5px;
    padding: 1rem;
    font-family: var(--font-familiy);
    font-size: 16px;
}
.formInput{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
}
.row{
    width: 100%;
    display: flex;
}
.form input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 16px;
}


.form  label{
    font-size: 16px;
    color: gray;
}

.form span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

.form input:invalid[focused="true"]{
    border: 1px solid red;
}

.form input:invalid[focused="true"] ~ span{
    display: block;
}
@media screen and (max-width: 1050px) {
    .contact_header h2{
        color: #606060;
        font-weight: 800;
        font-size: 34px;
    }
    .row{
        flex-direction: column;
    }
    .contact{
        padding: 1.5rem 6rem;
    }
}
@media screen and (max-width: 700px) {
    .contact{
        padding: 1.5rem 2rem;
    }
    
}