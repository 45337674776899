.nft{
    background-color: #fff;
    position: relative;
    padding: 5rem 10rem 2rem 10rem;
}

.nft_facts{
    display:flex;
    justify-content: space-around;
    padding: 2rem;
}
.nft_facts .nft_fact{
    display: flex;
    color: #171616;
    background-color: #E0E0E0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    padding: 1rem;
    max-width: 300px;
}

.nft_facts .nft_fact h3{
    padding: 0.5rem;
    font-size: 21px;
}
.nft_facts .nft_fact p{
    padding: 1rem 0;
    color: #171616;
}
.nft_counter{
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    flex-direction: row;
}
.nft_header h2{
    color: #171616;
    font-weight: 800;
    font-size: 45px;
}
.nft_header p{
    color: #171616;
    max-width: 750px;
    font-weight: 500;
    min-width: 250px;
    margin-top: 1rem;
    padding: 0.5rem;
}
.nft_header div{
    width: 120px;
    height: 5px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    margin-top: 0.5rem;
}
@media screen and (max-width: 1050px) {
    .nft_counter{
        flex-direction: column;
    }
    .nft_facts{
        flex-direction: column;
        align-items: center;
    }
    .nft_facts .nft_fact{
        margin: 0.8rem;
    }
    
    
    
}
@media screen and (max-width: 1050px) {
    .nft_header h2{
        color: #171616;
        font-weight: 800;
        font-size: 34px;
    }
    .nft_counter{
        flex-direction: column;
    }
    .nft{
        padding: 1.5rem 6rem;
    }
    
}
@media screen and (max-width: 700px) {
    .nft{
        padding: 1.5rem 2rem;
    }
    
}